import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import { transformArrayIntoMap } from 'common/util/mapArray'
import constants from 'app/constants'
import { getMarketType } from 'common/util/currencies'

const initialState = {
  pool: { data: {}, status: {}, error: {} },
  feed: { status: constants.IDLE, data: [], pagination: {}, error: null, marketType: null, queryString: '' },
  categories: { status: {}, data: {}, pagination: {}, error: {} },
  tags: { status: {}, data: {}, pagination: {}, error: {} },
  related_markets: [],
  hot_markets: [],
}

// Reducers / Slices
const mergeObjects = (obj1, obj2) => {
  const mergedObj = { ...obj1 } // Start with a shallow copy of obj1

  for (const id in obj2) {
    if (mergedObj.hasOwnProperty(id)) {
      // If the ID exists in both objects, merge the properties
      mergedObj[id] = { ...mergedObj[id], ...obj2[id] }
    } else {
      // If the ID is unique to obj2, add it to the merged object
      mergedObj[id] = { ...obj2[id] }
    }
  }

  return mergedObj
}
const markets = createSlice({
  name: 'markets',
  initialState,
  reducers: {
    resetAllMarkets: state => {
      state.feed = { ...initialState.feed }
      state.categories = { ...initialState.categories }
      state.tags = { ...initialState.tags }
      state.pool = { ...initialState.pool }
    },

    getFeedStart: (state, action) => {
      const { marketType } = action.payload
      state.feed.marketType = marketType

      state.feed.status = constants.LOADING
    },

    getFeedSuccess: (state, action) => {
      const { results, pagination, queryString } = action.payload
      // if (state.feed.marketType !== marketType) return
      const ids = results.map(el => el.id)
      const markets = transformArrayIntoMap(results)
      state.feed.queryString = queryString
      state.feed.status = constants.COMPLETE
      state.feed.pagination = pagination
      state.feed.data = state.feed.data.concat(ids)
      state.pool.data = { ...state.pool.data, ...markets }
    },

    getFeedError: (state, action) => {
      state.feed.error = action.payload
      state.feed.status = constants.ERROR
    },

    removeFromFeed: (state, action) => {
      const marketId = action.payload
      state.feed.data = state.feed.data.filter(id => id !== marketId)
    },

    resetFeed: state => {
      state.feed = { ...initialState.feed, status: constants.IDLE, marketType: state.feed.marketType }
      state.pool = { ...initialState.pool }
    },

    resetCategory: (state, action) => {
      const { categoryId } = action.payload
      state.categories.data[categoryId] = []
      state.categories.status[categoryId] = constants.IDLE
      state.categories.pagination[categoryId] = {}
    },

    getMarketByIdStart: (state, action) => {
      const marketId = action.payload
      state.pool.status[marketId] = constants.LOADING
    },

    getMarketByIdSuccess: (state, action) => {
      const market = action.payload.data
      state.pool.data[market.id] = { ...market, complete: true } 
      state.pool.status[market.id] = constants.COMPLETE
    },
    getRelatedMarketSuccess: (state, action) => {
      state.related_markets = action.payload.data
    },
    getHotMarketSuccess: (state, action) => {
      state.hot_markets = action.payload.data
    },

    getMarketByIdError: (state, action) => {
      const { marketId, error } = action.payload
      state.pool.status[marketId] = constants.ERROR
      state.pool.error[marketId] = error
    },

    updateMarketOutcomes: (state, action) => {
      const { marketId, data, activeCurrency } = action.payload
      const market = state.pool.data[marketId]
      if (market) {
        if (data.currency_mode === activeCurrency) {
          data.outcomes = data.outcomes.map(outcome => ({
            ...market.outcomes.find(o => o.id === outcome.id),
            ...outcome,
          }))
          state.pool.data[marketId] = { ...market, ...data }
        } else {
          state.pool.data[marketId] = { ...market, ...data, outcomes: market.outcomes }
        }
      }
    },

    updateMarket: (state, action) => {
      const { market } = action.payload
      const currentMarket = state.pool.data[market.id]

      if (!currentMarket) {
        state.pool.data[market.id] = { ...currentMarket, complete: Boolean(currentMarket.scoring_rule_metadata) }
      } else {
        state.pool.data[market.id] = { ...currentMarket, ...market, complete: currentMarket.complete }
      }
    },

    getMarketsByTagStart: (state, action) => {
      const { tag } = action.payload
      state.tags.status[tag] = constants.LOADING
    },

    getMarketsByTagSuccess: (state, action) => {
      const { tag, marketsList, pagination } = action.payload
      const markets = transformArrayIntoMap(marketsList)
      const marketIds = marketsList.map(market => market.id)

      if (!state.tags.data[tag]) {
        state.tags.data[tag] = []
      }

      state.pool.data = { ...state.pool.data, ...markets }
      state.tags.status[tag] = constants.COMPLETE
      state.tags.data[tag].push(...marketIds)
      state.tags.pagination[tag] = pagination
    },

    getMarketsByTagError: (state, action) => {
      const { tag, error } = action.payload
      state.tags.status[tag] = constants.ERROR
      state.tags.error[tag] = error
    },

    getMarketByCategoryIdStart: (state, action) => {
      const { categoryId } = action.payload
      state.categories.status[categoryId] = constants.LOADING
    },

    getMarketByCategoryIdSuccess: (state, action) => {
      const { categoryId, marketsList, pagination } = action.payload
      const markets = transformArrayIntoMap(marketsList)
      const marketIds = marketsList.map(market => market.id)

      if (!state.categories.data[categoryId]) {
        state.categories.data[categoryId] = []
      }

      state.pool.data = { ...state.pool.data, ...markets }
      state.categories.status[categoryId] = constants.COMPLETE
      state.categories.data[categoryId].push(...marketIds)
      state.categories.pagination[categoryId] = pagination
    },

    getMarketByCategoryIdError: (state, action) => {
      const { categoryId, error } = action.payload
      state.categories.status[categoryId] = constants.ERROR
      state.categories.error[categoryId] = error
    },

    addBetToMarket: (state, action) => {
      const { bet, marketId } = action.payload
      const market = state.pool && state.pool.data[marketId]
      if (market) {
        if (Array.isArray(market.wagers)) {
          market.wagers.unshift({ ...bet })
        } else {
          market.wagers = [{ ...bet }]
        }
      }
    },
    updateBetInMarket: (state, action) => {
      const { bet, marketId } = action.payload
      const market = state.pool && state.pool.data[marketId]
      if (market) {
        if (Array.isArray(market.wagers)) {
          market.wagers.forEach((w, i) => {
            if (w.id === bet.id) {
              market.wagers[i] = bet
            }
          })
        }
      }
    },

    addMarketsToPool: (state, action) => {
      const { markets } = action.payload
      if (state.pool && state.pool.data) {
        const marketsMap = transformArrayIntoMap(markets)
        state.pool.data = mergeObjects(state.pool.data, marketsMap)
      }
    },
  },
})

export const {
  getFeedError,
  getFeedStart,
  getFeedSuccess,
  resetFeed,
  resetCategory,
  removeFromFeed,
  resetAllMarkets,
  getMarketsByTagStart,
  getMarketsByTagSuccess,
  getMarketsByTagError,
} = markets.actions
export const {
  getMarketByIdStart,
  getMarketByIdError,
  getMarketByIdSuccess,
  getRelatedMarketSuccess,
  getHotMarketSuccess,
} = markets.actions
export const { getMarketByCategoryIdStart, getMarketByCategoryIdSuccess, getMarketByCategoryIdError } = markets.actions
export const { updateMarketOutcomes, addBetToMarket, updateBetInMarket, updateMarket, addMarketsToPool } =
  markets.actions
export default markets.reducer

// Selectors:

export const selectMarketRepository = state => state.markets.pool.data

export const selectFeedMarkets = state => state.markets.feed.data

export const selectFeedMarketsPagination = state => state.markets.feed.pagination

export const selectFeedMarketsError = state => state.markets.feed.error

export const selectFeedMarketsStatus = state => state.markets.feed.status

export const selectFeedMarketType = state => state.markets.feed.marketType

export const selectShouldLoadFeed =
  (marketType, queryString = '') =>
  state => {
    return (
      state.markets.feed.marketType !== marketType ||
      state.markets.feed.status === constants.IDLE ||
      state.markets.feed.queryString !== queryString
    )
  }

export const selectMarketById = marketId => state => state.markets.pool.data[marketId]
export const selectRelatedMarketById = () => state => state.markets.related_markets
export const selectHotMarket = () => state => state.markets.hot_markets

export const selectMarketStatusById = marketId => state => state.markets.pool.status[marketId]

export const selectMarketErrorById = marketId => state => state.markets.pool.error[marketId]

export const selectMarketsByCategoryId = categoryId => state => {
  const marketIds = state.markets.categories.data[categoryId]
  if (marketIds) {
    return marketIds.map(id => state.markets.pool.data[id])
  }
  return []
}

export const selectMarketsByTagName = tagName => state => {
  const marketIds = state.markets.tags.data[tagName]
  if (marketIds) {
    return marketIds.map(id => state.markets.pool.data[id])
  }
  return []
}
export const fetchMarketsById = marketId => async dispatch => {
  try {
    var response = await requestService.get(`/wagers/?question=` + marketId)
  } catch (error) {
    return
  }
  const data = await response.json()
  data.results.forEach(bet => {
    dispatch(updateBetInMarket({ bet, marketId }))
  })
}

export const selectMarketsStatusByTagName = tagName => state => state.markets.tags.status[tagName] || constants.IDLE

export const selectMarketsErrorByTagName = tagName => state => state.markets.tags.error[tagName]

export const selectMarketsPaginationByTagName = tagName => state => state.markets.tags.pagination[tagName]

export const selectMarketsStatusByCategoryId = categoryId => state =>
  state.markets.categories.status[categoryId] || constants.IDLE

export const selectMarketsErrorByCategoryId = categoryId => state => state.markets.categories.error[categoryId]

export const selectMarketsPaginationByCategoryId = categoryId => state =>
  state.markets.categories.pagination[categoryId]

// Thunks:
export const fetchFeed =
  (marketType, options = {}, queryString = '', shouldResetFeed = false) =>
  async dispatch => {

    if (shouldResetFeed) {
      await dispatch(resetFeed())
    }

    
    const params = requestService.parseFilters(options)
    params.set('currency_mode', marketType)
    await dispatch(getFeedStart({ marketType }))
    try {
      var response = await requestService.get(`/questions/?${params.toString()}`)
    } catch (err) {
      dispatch(getFeedError(err))
      return
    }
    
    await dispatch(getFeedSuccess({ ...(await response.json()), queryString, marketType }))
  }

export const fetchMarketById =
  (marketId, marketType, silent = false) =>
  async dispatch => {
    if (!silent) {
      dispatch(getMarketByIdStart(marketId))
    }
    const params = requestService.parseFilters({})
    params.set('currency_mode', marketType)
    try {
      var response = await requestService.get(`/questions/${marketId}/?${params.toString()}`)
    } catch (error) {
      dispatch(getMarketByIdError({ marketId, error }))
      return
    }
    const data = await response.json()
    dispatch(getMarketByIdSuccess({ data }))
    return data
  }
export const fetchRelatedMarketById = marketId => async dispatch => {
  try {
    var response = await requestService.get(`/questions/${marketId}/related_markets/`)
  } catch (error) {
    dispatch(getMarketByIdError({ marketId, error }))
    return
  }
  if (response) {
    const data = await response.json()
    dispatch(getRelatedMarketSuccess({ data }))
    return data
  }
}

export const fetchMarkets = async (marketType, options) => {
  const currencyParam = getMarketType(marketType === constants.REAL_MONEY)
  const params = requestService.parseFilters(options)
  params.set('currency_mode', currencyParam)
  return requestService.get(`/questions/?${params.toString()}`)
}

export const fetchHotMarkets = options => async dispatch => {
  const params = requestService.parseFilters(options)

  try {
    var response = await requestService.get(`/questions/hot/?${params.toString()}`)
  } catch (error) {
    return
  }
  if (response) {
    const data = await response.json()
    dispatch(getHotMarketSuccess({ data }))
    return data
  }
}
export const followOrUnfollow = marketId => async dispatch => {
  try {
    var response = await requestService.post(`/questions/${marketId}/toggle_follow/`)
  } catch (error) {
    dispatch(getMarketByIdError({ marketId, error }))
    return
  }

  const data = await response.json()
  await dispatch(updateMarket({ market: { id: marketId, is_following: data.is_following } }))
}
export const fetchMarketsByCategoryId =
  (categoryId, marketType, options = {}) =>
  async dispatch => {
    dispatch(getMarketByCategoryIdStart({ categoryId }))
    try {
      var response = await fetchMarkets(marketType, { ...options, categories: categoryId })
    } catch (error) {
      dispatch(getMarketByCategoryIdError({ categoryId, error }))
      throw error
    }
    const { results, pagination } = await response.json()
    dispatch(getMarketByCategoryIdSuccess({ categoryId, marketsList: results, pagination }))
  }

export const fetchTagsByName =
  (tagName, marketType, options = {}) =>
  async dispatch => {
    dispatch(getMarketsByTagStart({ tag: tagName }))
    try {
      var response = await fetchMarkets(marketType, { ...options, tag: tagName })
    } catch (error) {
      dispatch(getMarketsByTagError({ tag: tagName, error }))
      throw error
    }
    const { results, pagination } = await response.json()
    dispatch(getMarketsByTagSuccess({ tag: tagName, marketsList: results, pagination }))
  }

export const resetCategoryMarkets = categoryId => dispatch => {
  dispatch(resetCategory({ categoryId }))
}
