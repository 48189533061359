import React from 'react'
import classnames from 'classnames'
import './UserPicture.scss'

export default function UserPicture({ user, children, height, width, ...props }) {
  const classList = classnames('user-picture', props.className)
  let style = {}
  if (user && user.picture) {
    style.backgroundImage = 'none'
  }

  if (height) {
    style.height = height + 'px'
  }

  if (width) {
    style.width = width + 'px'
  }
  return (
    <div className={classList} style={style}>
      {user && user.picture && (
        <img
          src={user.picture}
          className="user-picture-img"
          alt={user.username + "'s picture"}
          title={user.username + "'s picture"}
        />
      )}
      {children}
    </div>
  )
}
