import { createSlice } from '@reduxjs/toolkit'
import constants from 'app/constants'
import requestService from 'common/services/request'

const initialState = {
  pool: {
    data: [],
    status: constants.IDLE,
    error: null,
    pagination: {},
    users: [],
  },
}

const comments = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetAllComments: state => {
      state.pool.data = initialState.data
      state.pool.users = initialState.users
      state.pool.status = initialState.status
      state.pool.error = initialState.error
      state.pool.pagination = initialState.pagination
    },

    getCommentsStart: (state, action) => {
      const { silent } = action.payload
      if (!silent) state.pool.status = constants.LOADING
    },

    getCommentsSuccess: (state, action) => {
      const { data, included, pagination, silent } = action.payload
      state.pool.status = constants.COMPLETE
      if (pagination) state.pool.pagination = pagination
      if (!silent) {
        state.pool.data = data
        state.pool.users = included.filter(comment => comment.type === 'users')
      } else {
        state.pool.data = [...state.pool.data, ...data]
        state.pool.users = [...state.pool.users, ...included.filter(comment => comment.type === 'users')]
      }
    },

    getCommentsError: (state, action) => {
      state.pool.error = action.payload
      state.pool.status = constants.ERROR
    },
  },
})
export const { resetAllComments, getCommentsStart, getCommentsSuccess, getCommentsError } = comments.actions
export default comments.reducer
export const selectComments = () => state => state.comments.pool.data
export const selectCommentsUsers = () => state => state.comments.pool.users
export const selectCommentsPagination = () => state => state.comments.pool.pagination
export const selectCommentsStatus = () => state => state.comments.pool.status
export const selectCommentsError = () => state => state.comments.pool.error

// Thunks:
export const fetchComments =
  (questionId, options = {}, silent = false) =>
  async dispatch => {
    const params = requestService.parseFilters(options)
    await dispatch(getCommentsStart({ silent }))
    try {
      var response = await requestService.get(`/questions/${questionId}/get_comments?${params.toString()}`)
      var json = await response.json()
      var findFutuurBot = json.data.find(d => d.attributes.number === 1)
    } catch (err) {
      // dispatch(getCommentsError(err))
      return
    }

    await dispatch(
      getCommentsSuccess({
        ...json,
        pagination: options.offset
          ? null
          : { ...json.pagination, total: Number(json.pagination.total) - (findFutuurBot ? 1 : 0) },
        silent,
      })
    )
  }
